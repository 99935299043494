html,
body,
#root {
  height: 100%;
  margin: 0;
}

.app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.nav {
  flex: 0 0 40px;
  width: 100%;
}

.main {
  flex: 1 1 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.main-inner {
  height: 100%;
}

#page-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto-Regular.ttf)
}