.nav {
  box-sizing: border-box;
  display: flex;
  z-index: 1000;
  background-color: white;
  padding: 6px;

  /* dev */
  border-style: none;

  justify-content: space-between;
  align-items: center;
}

.nav .button-container {
  display: flex;
}

.nav .button-container .button {
  margin-left: 8px;
  color: #f6801f;
}