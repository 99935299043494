.home {
  height: 100%;
  width: 100%;

  padding-left: 8px;
  box-sizing:border-box;

  background-image: url('./work.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  
  font-family: 'Roboto';
}

.home .title {
  color: rgb(255, 255, 255);
  font-size: 3em;
}

.home .subtitle {
  margin-top: 24px;
  color: rgb(255, 164, 73);
  font-size: 1em;
  text-align: right;
}

.home .left {
  /* margin-top: 200px; */
  margin-left: 20px;
  height: 100%;
}
.home .left img {
  max-width: 200px;
}

.home a {
  color: rgb(35, 110, 196);
}
.home a:visited {
  color: rgb(35, 110, 196);
}