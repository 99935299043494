.beginnings {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.beginnings h1, .beginnings p {
    font-family: Roboto;
}
.beginnings h1 {
    color: rgb(255, 164, 73);
}

.beginnings .img {
    background-image: url("./beginnings.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 1 50%;
    height: 100%;
    width: 100%;
}

.beginnings .content {
    flex: 1 1 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 12px;
    color: white;
    line-height: 2em;
}

@media only screen and (max-width: 428px) {
    .beginnings {
        flex-direction: column;
    }
}