.about {
    height: 100%;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
}

.about .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100%;
    width: 100%; */
    color: black;
}