/* SlideLeft */
.slideLeft {
    position:relative;
}

.slideLeft.start {
    left: 50%;
    opacity: 0;
}

.slideLeft.end {
    left: 0;
    opacity: 1;
}

/* Fade */
.fade {
   position: relative;
}
.fade.start {
    opacity: 0;
}
.fade.end {
    opacity: 1;
}