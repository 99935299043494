.section {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */

  /* Dev */
  border-style: none;
  box-sizing: border-box;
  min-height: 100%;
  margin: 0;
}
